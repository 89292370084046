
          @charset "utf-8";
          @import "~bulma/sass/utilities/initial-variables.sass";
          @import "~bulma/sass/utilities/functions.sass";
          @import "~bulma/sass/utilities/derived-variables.sass";
          @import "~bulma/sass/utilities/mixins.sass";

          @import "~@/scss/_theme-default.scss";
          @import "~@/scss/_variables.scss";
          @import "~@/scss/_mixins.scss";
        

::v-deep {
  .modal-card {
    max-width: 800px;
    width: 100vw;
  }
}

.row-grid {
  &--checkbox-cover {
    grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
  }
}
